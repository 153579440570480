import revive_payload_client_zSJwl7d7FH from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MGe7cZdcMv from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5H31jg8T7x from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qlYnHbX4bc from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.2_sass@1.77_vacqbsuz64qxofoowqjpdw5aui/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_JUZfjplBdC from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uCABzjYTmg from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_eLfqYONevv from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vZVcOtHYeq from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QwXAJ1WePq from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.19.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_xXwVtTcbkp from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.2_eslint@8.57.0_ioredis@5.4.1_magicast@0._ek4fin7lqnfo7u4ybo6ex6wleq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_2TIAxixvMN from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.1_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.2_sass@1.77.8_mdchkth3ccgeyikj7w7gqi5vba/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import switch_locale_path_ssr_lW9d7YzdyZ from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.19.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_YRdYWsz7oG from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.19.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_fkSYYcWvxS from "/app/node_modules/.pnpm/@nuxt+image@1.7.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/plugin.js";
import autosize_N8mFgrWbV7 from "/app/plugins/autosize.ts";
import request_8cwBTcUfTa from "/app/plugins/request.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_zSJwl7d7FH,
  unhead_MGe7cZdcMv,
  router_5H31jg8T7x,
  _0_siteConfig_qlYnHbX4bc,
  payload_client_JUZfjplBdC,
  navigation_repaint_client_uCABzjYTmg,
  check_outdated_build_client_eLfqYONevv,
  chunk_reload_client_vZVcOtHYeq,
  plugin_vue3_QwXAJ1WePq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xXwVtTcbkp,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_2TIAxixvMN,
  switch_locale_path_ssr_lW9d7YzdyZ,
  i18n_YRdYWsz7oG,
  plugin_fkSYYcWvxS,
  autosize_N8mFgrWbV7,
  request_8cwBTcUfTa,
  sentry_client_shVUlIjFLk
]