export default () => {
  const userStore = useUserStore();

  const append = (
    event: object,
    additional: (object | false | null | undefined | 'user')[],
  ) => {
    dataLayer.push(
      Object.assign(
        event,
        ...additional
          .map((el) => {
            if (el === 'user') {
              return (
                userStore.authorized &&
                userStore.user && {
                  userid: userStore.user.id,
                  usermail: userStore.user.email,
                  userphone: userStore.user.name,
                }
              );
            }
            return el;
          })
          .filter(Boolean),
      ),
    );
  };

  return { append };
};
