<script lang="ts" setup>
import placeholder from '~/assets/icons/placeholder.svg?url';

const TEST_ID = 'product-cart-item';

const props = withDefaults(
  defineProps<{
    name: string;
    code?: string | null;
    picture: string;
    value?: string | null;
    price?: number;
    basePrice?: number | null;
    count?: number;
    mode?: 'cart' | 'subscription' | 'history';
    skuId?: number;
    productId?: number;
    repeat?: boolean;
    small?: boolean;
    isAvailable?: boolean;
    additional?: string;
    discount?: number | string;
  }>(),
  { mode: 'cart', isAvailable: true },
);
const count = toRef(props, 'count');
const emit = defineEmits<{
  (event: 'update:count', value: number): void;
  (event: 'delete'): void;
}>();

const { t } = useI18n();
const productCartStore = useProductCartStore();

const localCount = ref(count.value);
const addedTimer = ref<NodeJS.Timeout>();
const added = ref(false);

const price = computed(() => props.price && priceFormat(props.price));
const basePrice = computed(
  () => props.basePrice && priceFormat(props.basePrice),
);
const totalPrice = computed(
  () =>
    props.price &&
    localCount.value &&
    priceFormat(props.price * localCount.value),
);

const onRepeat = () => {
  if (!props.skuId || !props.productId || !props.price) return;

  productCartStore.updateCartItem(
    {
      quantity: 1,
      skuId: props.skuId,
      productId: props.productId,
      code: props.code,
      name: props.name,
      price: props.price,
      basePrice: props.basePrice,
      isAvailable: props.isAvailable,
    },
    true,
  );

  added.value = true;
  clearTimeout(addedTimer.value);
  addedTimer.value = setTimeout(() => {
    added.value = false;
  }, 3000);
};

const onUpdateCount = (value: number) => {
  if (value) {
    localCount.value = value;
    emit('update:count', value);
  } else emit('delete');
};

watch(count, (v) => {
  localCount.value = v;
});
</script>

<template>
  <div
    class="productCartItem"
    :class="[`productCartItem_${mode}`, { productCartItem_small: small }]"
    :data-test-id="TEST_ID"
    :data-product-type="mode"
  >
    <div class="productCartItem__imageContainer">
      <NuxtImg
        format="webp"
        :src="picture"
        width="125"
        height="125"
        :placeholder="placeholder"
        class="productCartItem__image"
        :class="{ productCartItem_notAvailable: !isAvailable }"
      />
      <span v-if="discount" class="productCartItem__discount">
        {{ t('discount', { discount }) }}
      </span>
    </div>

    <div class="productCartItem__content">
      <div
        class="productCartItem__info"
        :class="{ productCartItem__info_notAvailable: !isAvailable }"
      >
        <div class="productCartItem__parameters">
          <NuxtLink
            v-if="code"
            :to="`/product/${code}`"
            class="productCartItem__link"
          >
            <span>{{ name }}</span>
          </NuxtLink>
          <span v-else>{{ name }}</span>
          <div v-if="isAvailable" class="productCartItem__price">
            <span v-if="price">{{ price }}</span>
            <span v-if="basePrice" class="productCartItem__price_base">
              {{ basePrice }}
            </span>
          </div>
          <span v-if="value">{{ value }}</span>
        </div>

        <button
          v-if="['cart', 'subscription'].includes(mode)"
          @click="emit('delete')"
          class="productCartItem__remove"
          :data-test-id="`${TEST_ID}-delete`"
        >
          {{ t('delete') }}
        </button>
        <BaseButton
          v-else-if="repeat"
          @click="onRepeat"
          dark
          outlined
          :icon="added ? 'check' : 'plus'"
          size="s"
          class="productCartItem__repeat"
        />
      </div>

      <span v-if="additional" class="productCartItem__additional">
        {{ additional }}
      </span>
      <div v-if="isAvailable" class="productCartItem__footer">
        <BaseCounter
          v-if="mode === 'cart'"
          @update:value="onUpdateCount"
          :value="localCount"
          size="s"
          outlined
          :limit="6"
          :data-test-id="`${TEST_ID}-counter`"
        />
        <span
          v-if="localCount && mode === 'history'"
          class="productCartItem__info"
        >
          {{ t('quantity', { count: localCount }) }}
        </span>
        <span v-if="totalPrice && ['cart', 'history'].includes(mode)">
          {{ totalPrice }}
        </span>
      </div>
      <span v-else class="productCartItem__notAvailable">
        {{ t('no_stock') }}
      </span>
    </div>
  </div>
</template>

<i18n>
ru:
  discount: -{discount}%
  delete: Удалить
  quantity: 'Кол-во: {count}'
  no_stock: Этого товара нет в наличии. На него не будет оформлен заказ.

en:
  discount: -{discount}%
  delete: Delete
  quantity: 'Quantity: {count}'
  no_stock: This product is not available. No order will be placed for it.
</i18n>

<style lang="scss">
.productCartItem {
  $self: &;

  display: flex;
  gap: 40px;

  &_notAvailable {
    opacity: 0.5;
  }

  &_subscription &__image {
    @include mq('sm') {
      grid-row: 1 / 3;
    }
  }

  &_history {
    column-gap: 25px;
    row-gap: 9px;
    display: grid;
    grid-template-columns: auto 1fr;

    #{$self}__content {
      display: contents;
    }

    #{$self}__footer {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
    }
  }

  @include mq('sm') {
    column-gap: 15px;
    row-gap: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  &_small {
    gap: 24px;

    @include mq('sm') {
      gap: 16px;
    }

    #{$self}__image {
      width: 116px;
      height: 116px;
    }
  }

  &__imageContainer {
    position: relative;
    width: 125px;
    height: 125px;

    @include mq('sm') {
      width: 100px;
      height: 100px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    pointer-events: none;
  }

  &__discount {
    position: absolute;
    width: 27px;
    height: 27px;
    background-color: #8ab844;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    left: 7px;

    @include mq('sm') {
      width: 20px;
      height: 20px;
      top: 6px;
      left: 5px;
      font-size: 7px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 14px;

    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;

    @include mq('md') {
      gap: 4px;
    }

    @include mq('sm') {
      display: contents;
    }
  }

  &__info {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 12px;

    &_notAvailable {
      opacity: 0.5;
    }
  }

  &__parameters {
    display: flex;
    flex-direction: column;
    gap: 2px;

    @include mq('sm') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__link {
    display: block;
    color: inherit;
  }

  &__notAvailable {
    @include mq('sm') {
      grid-column-start: 1;
      grid-row-start: 2;
      grid-column-end: 3;
    }
  }

  &__additional {
    color: #121212;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin-top: auto;

    @include mq('sm') {
      grid-column: 2 / 3;
    }
  }

  &__price {
    display: flex;
    gap: 11px;

    &_base {
      color: #c0c0c0;
      text-decoration-line: line-through;
    }

    @include mq('xs') {
      flex-direction: column;
      gap: 2px;
    }
  }

  &__remove {
    padding: 0;
    background: none;
    border: none;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #c0c0c0;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-left: auto;

    &:hover {
      color: #121212;
    }
  }

  & &__repeat {
    height: 42px;
    width: 42px;
    flex: 1 0 42px;
    margin-left: auto;
  }

  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &:empty {
      display: none;
    }

    @include mq('sm') {
      grid-column: 1 / 3;
    }
  }
}
</style>
