import { defineStore } from 'pinia';

import type { SubscribeProductsInfoType } from '~/api/subscribe-product';
import { SubscribeProductAPI } from '~/api/subscribe-product';

const SUBSCRIBE_DATA_VERSION = 1;
const SUBSCRIBE_DATA_STORAGE_KEY = 'subscribe-data';

export const useSubscribeProductsStore = defineStore(
  'subscribe-products',
  () => {
    const t = useNuxtApp().$i18n.t;
    const commonStore = useCommonStore();
    const modalStore = useModalStore();
    const userStore = useUserStore();

    const productIds = ref<number[]>([]);
    const abortController = shallowRef<AbortController>();
    const nextDate = ref(
      (() => {
        const date = new Date();
        date.setMonth(date.getMonth() + 3);
        return date.toLocaleDateString('ru-RU', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        });
      })(),
    );

    const offlineStore = useOfflineStore(
      productIds,
      SUBSCRIBE_DATA_STORAGE_KEY,
      SUBSCRIBE_DATA_VERSION,
    );

    const products = computed(() =>
      commonStore.products.filter((el) => productIds.value.includes(el.id)),
    );

    const applyData = (data: SubscribeProductsInfoType) => {
      productIds.value = data.products;
    };

    const fetchSubscribeProducts = async () => {
      if (userStore.authorized) {
        await SubscribeProductAPI.current().then((value) => {
          if (Object.keys(value).length) applyData(value);
        });
      }

      offlineStore.init();
    };

    const onToggle = (v: boolean, productId: number) => {
      if (userStore.authorized) {
        abortController.value?.abort();
        abortController.value = new AbortController();

        SubscribeProductAPI[v ? 'append' : 'delete'](
          {
            body: { productId },
          },
          {
            signal: abortController.value.signal,
          },
        ).then(applyData);
      } else {
        productIds.value = v
          ? productIds.value.concat(productId)
          : productIds.value.filter((el) => el !== productId);
      }
    };

    const onDelete = (productId: number) => {
      productIds.value = productIds.value.filter((el) => el !== productId);

      if (userStore.authorized) {
        SubscribeProductAPI.delete({
          body: { productId },
        }).then(applyData);
      }
    };

    const clear = () => {
      productIds.value = [];
      offlineStore.reset();
    };

    const transfer = async () => {
      if (!userStore.authorized || !productIds.value.length) return;

      return SubscribeProductAPI.transfer({
        body: { products: productIds.value },
      })
        .then((data) => {
          if (data.products.length > productIds.value.length) {
            modalStore.showInfo({
              title: t('store.subscribe.message.transfer_info'),
              action: t('store.subscribe.message.continue'),
            });
          }

          applyData(data);
          offlineStore.reset();
        })
        .catch(() => {
          modalStore.showError({
            title: t('store.subscribe.message.transfer_error'),
            action: t('store.subscribe.message.action'),
          });
        });
    };

    return {
      productIds,
      products,
      nextDate,
      fetchSubscribeProducts,
      onToggle,
      onDelete,
      clear,
      transfer,
    };
  },
);
