import { number, object, ref, string } from 'yup';

import request from '~/api/utils';
import { HTTPMethod } from '~/api/utils/types';

const authSchema = object().shape({
  id: number().required(),
  name: string().nullable(),
  lastName: string().nullable(),
  token: string().required(),
  expiresIn: number().required(),
});

const loginAuthBodySchema = object().shape({
  login: string().email().required(),
  password: string().required(),
});

const phoneAuthBodySchemas = object().shape({ phone: string().required() });

const checkPhoneCodeBodySchemas = object().shape({
  code: string().required(),
  phone: string().required(),
});

const forgotPasswordBodySchemas = object().shape({
  email: string().email().required(),
});

const changePasswordBodySchemas = object().shape({
  password: string().required().min(8),
  confirmPassword: string()
    .required()
    .oneOf([ref('password')]),
  login: string().required(),
  checkWord: string().required(),
});

const AuthAPI = {
  loginAuth: request.prepare({
    url: 'auth/login-password',
    method: HTTPMethod.post,
    schemas: { response: authSchema, body: loginAuthBodySchema },
    options: { groupKey: 'auth' },
  }),
  forgotPassword: request.prepare({
    url: 'auth/forgot-password',
    method: HTTPMethod.post,
    schemas: { body: forgotPasswordBodySchemas },
    options: { groupKey: 'auth' },
  }),
  changePassword: request.prepare({
    url: 'auth/change-password',
    method: HTTPMethod.post,
    schemas: { body: changePasswordBodySchemas },
    options: { groupKey: 'auth' },
  }),
  phoneAuth: request.prepare({
    url: 'auth/code',
    method: HTTPMethod.post,
    schemas: { body: phoneAuthBodySchemas },
    options: { groupKey: 'auth' },
  }),
  checkPhoneCode: request.prepare({
    url: 'auth/code',
    method: HTTPMethod.put,
    schemas: { response: authSchema, body: checkPhoneCodeBodySchemas },
    options: { groupKey: 'auth' },
  }),
  logout: request.prepare({
    url: 'auth/logout',
    method: HTTPMethod.post,
    options: { groupKey: 'auth' },
  }),
};

export default AuthAPI;
