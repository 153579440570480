import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import request from '~/api/utils';
import { HTTPMethod } from '~/api/utils/types';

const productSchema = object().shape({
  id: number().required(),
  code: string().required(),
  name: string().required(),
  sectionIds: array().ensure().of(string().required()).required(),
  picture: string().nullable(),
  altPicture: string().nullable(),
  volume: string().nullable(),
  isAvailable: boolean().required(),
  addToBasketId: number().nullable(),
  xmlId: string().required(),
  refillId: number().required().nullable(),
  priceInfo: object()
    .shape({
      price: number().required(),
      basePrice: number().nullable(),
      startsFrom: boolean().required(),
    })
    .required(),
});

const sectionSchema = object()
  .shape({
    id: string().required(),
    name: string().required(),
    code: string().required(),
    seo: object({
      title: string().defined().nullable(),
      description: string().defined().nullable(),
      keywords: string().defined().nullable(),
    }).required(),
  })
  .required();

const commonSchema = object()
  .shape({
    products: array().ensure().of(productSchema).required(),
    sections: array().ensure().of(sectionSchema).required(),
    isTestGroup: boolean().required(),
  })
  .required();

const productsSchema = array().ensure().of(productSchema).required();

const mainPageBannerSchema = object()
  .shape({
    name: string().required().nullable(),
    url: string().required(),
    desktopImage: string().required(),
    mobileImage: string().required(),
  })
  .required();

const mainPageNewsItemSchema = object()
  .shape({
    name: string().required(),
    link: string().required(),
    picture: string().required(),
  })
  .required();

const mainSchema = object().shape({
  banners: array().ensure().of(mainPageBannerSchema).required(),
  newProductIds: array().ensure().of(number().required()).required(),
  hitProductIds: array().ensure().of(number().required()).required(),
  news: array().ensure().of(mainPageNewsItemSchema).required(),
});

const subscribeBodySchema = object().shape({
  email: string().email().required(),
});

const writeLetterBodySchema = object().shape({
  email: string().email().required(),
  name: string().required(),
  message: string().required(),
});

export const pickUpPointSchema = object().shape({
  id: number().required(),
  coords: array().ensure().of(number().required()).required(),
  address: string().required(),
  city: string().required(),
  street: string().required(),
  service: string().required(),
  pvzcode: string().required(),
});

const pickUpPointsResponseSchema = array()
  .ensure()
  .of(pickUpPointSchema)
  .required();

const addressSuggestionQuerySchema = object().shape({ q: string().required() });

const addressSuggestionSchema = object().shape({
  value: string().required(),
  unrestrictedValue: string().required(),
  data: object()
    .shape({
      postalCode: string().required().nullable(),
      house: string().required().nullable(),
      block: string().required().nullable(),
      flat: string().required().nullable(),
      flatType: string().required().nullable(),
      regionWithType: string().required().nullable(),
      areaWithType: string().required().nullable(),
      cityWithType: string().required().nullable(),
      streetWithType: string().required().nullable(),
      settlementWithType: string().required().nullable(),
      houseType: string().required().nullable(),
      blockType: string().required().nullable(),
    })
    .required(),
});

const suggestAddressResponseSchema = object().shape({
  suggestions: array().ensure().of(addressSuggestionSchema).required(),
});

const locationItemSchema = object()
  .shape({
    code: string().required(),
    name: string().required(),
  })
  .required();

const locationsResponseSchema = array()
  .ensure()
  .of(locationItemSchema)
  .required();

export type ProductType = InferType<typeof productSchema>;

export type MainPageBannerType = InferType<typeof mainPageBannerSchema>;

export type MainPageNewsItemType = InferType<typeof mainPageNewsItemSchema>;

export type SectionType = InferType<typeof sectionSchema>;

export type PagesMainResponseType = InferType<typeof mainSchema>;

export type PickUpPointType = InferType<typeof pickUpPointSchema>;

export type AddressSuggestionType = InferType<typeof addressSuggestionSchema>;

export type LocationItemType = InferType<typeof locationItemSchema>;

export type MediaType = {
  id: string;
  image: string;
  category: string;
  title: string;
  description?: string;
  action: string;
  url?: string;
  to?: string;
};

const CommonAPI = {
  common: request.prepare({
    url: 'common',
    schemas: { response: commonSchema },
  }),
  products: request.prepare({
    url: 'products',
    schemas: { response: productsSchema },
  }),
  main: request.prepare({
    url: 'main',
    schemas: { response: mainSchema },
  }),
  subscribe: request.prepare({
    url: 'forms/subscribe',
    method: HTTPMethod.post,
    schemas: { body: subscribeBodySchema },
  }),
  writeLetter: request.prepare({
    url: 'forms/write-letter',
    method: HTTPMethod.post,
    schemas: { body: writeLetterBodySchema },
  }),
  pickUpPoints: request.prepare({
    url: 'delivery/pvz',
    schemas: { response: pickUpPointsResponseSchema },
  }),
  suggestAddress: request.prepare({
    url: 'client/suggest/address',
    schemas: {
      query: addressSuggestionQuerySchema,
      response: suggestAddressResponseSchema,
    },
  }),
  locations: request.prepare({
    url: 'locations',
    schemas: { response: locationsResponseSchema },
  }),
};

export default CommonAPI;
