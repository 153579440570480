import type { InferType, ObjectSchema } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import request from '~/api/utils';
import { HTTPMethod } from '~/api/utils/types';

const strictSubscribeProductsResponseSchema = object()
  .required()
  .shape({
    id: number().required(),
    userId: number().required(),
    active: boolean().required(),
    products: array().ensure().required().of(number().required()),
    nextDate: string().required(),
  });

// TODO: надо бы придумать лучше решение
const subscribeProductsResponseSchema: ObjectSchema<SubscribeProductsInfoType> =
  object().test((value) => {
    if (Object.keys(value).length)
      strictSubscribeProductsResponseSchema.validateSync(value);
    return true;
  }) as any;

const changeSubscribeProductsBodySchema = object().required().shape({
  productId: number().required(),
});

const transferSubscribeProductsBodySchema = object()
  .required()
  .shape({
    products: array().required().of(number().required()),
  });

const listSubscribeProductsResponseSchema = array()
  .required()
  .ensure()
  .of(strictSubscribeProductsResponseSchema);

export type SubscribeProductsInfoType = InferType<
  typeof strictSubscribeProductsResponseSchema
>;

export const SubscribeProductAPI = {
  current: request.prepare({
    url: 'subscribe-product/current',
    schemas: { response: subscribeProductsResponseSchema },
  }),
  append: request.prepare({
    url: 'subscribe-product/current',
    method: HTTPMethod.put,
    schemas: {
      body: changeSubscribeProductsBodySchema,
      response: strictSubscribeProductsResponseSchema,
    },
  }),
  delete: request.prepare({
    url: 'subscribe-product/current',
    method: HTTPMethod.delete,
    schemas: {
      body: changeSubscribeProductsBodySchema,
      response: strictSubscribeProductsResponseSchema,
    },
  }),
  transfer: request.prepare({
    url: 'subscribe-product/transfer',
    method: HTTPMethod.post,
    schemas: {
      body: transferSubscribeProductsBodySchema,
      response: strictSubscribeProductsResponseSchema,
    },
  }),
  list: request.prepare({
    url: 'subscribe-product',
    schemas: { response: listSubscribeProductsResponseSchema },
  }),
};
