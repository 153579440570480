import { default as change_45passwordX8ivx1vU2lMeta } from "/app/pages/auth/change-password.vue?macro=true";
import { default as indexaVPDnhf1OvMeta } from "/app/pages/auth/index.vue?macro=true";
import { default as auth7tE1sZcZDUMeta } from "/app/pages/auth.vue?macro=true";
import { default as aboutnGGe82ZLhXMeta } from "/app/pages/index/about.vue?macro=true";
import { default as _91slug_93EZ1Xz85okZMeta } from "/app/pages/index/blog/[slug].vue?macro=true";
import { default as indexllF7s3KxDgMeta } from "/app/pages/index/blog/index.vue?macro=true";
import { default as blogfzfR60KsaDMeta } from "/app/pages/index/blog.vue?macro=true";
import { default as cataloghkMyb8UUU4Meta } from "/app/pages/index/catalog.vue?macro=true";
import { default as contactsz1fQ6e3a2BMeta } from "/app/pages/index/contacts.vue?macro=true";
import { default as delivery_45and_45paymentZEcBSnT7oqMeta } from "/app/pages/index/delivery-and-payment.vue?macro=true";
import { default as faqtf6EVCpyWxMeta } from "/app/pages/index/faq.vue?macro=true";
import { default as index3J5r3Sf7qAMeta } from "/app/pages/index/index.vue?macro=true";
import { default as legalqBE5txWhZaMeta } from "/app/pages/index/legal.vue?macro=true";
import { default as index7vtlxWa4MWMeta } from "/app/pages/index/order/index.vue?macro=true";
import { default as successfulPAWfBRROrpMeta } from "/app/pages/index/order/successful.vue?macro=true";
import { default as _91slug_93uprPRV9i3iMeta } from "/app/pages/index/product/[slug].vue?macro=true";
import { default as profileZxBGWFYWGgMeta } from "/app/pages/index/profile.vue?macro=true";
import { default as public_45offersQCpyBg7YbMeta } from "/app/pages/index/public-offer.vue?macro=true";
import { default as social_45responsibilityZP7N5fIl22Meta } from "/app/pages/index/social-responsibility.vue?macro=true";
import { default as sustainablen6OmLmvrwYMeta } from "/app/pages/index/sustainable.vue?macro=true";
import { default as vacanciessWf8nRup48Meta } from "/app/pages/index/vacancies.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: auth7tE1sZcZDUMeta?.name,
    path: "/auth",
    component: () => import("/app/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-change-password___ru",
    path: "change-password",
    component: () => import("/app/pages/auth/change-password.vue").then(m => m.default || m)
  },
  {
    name: "auth___ru",
    path: "",
    component: () => import("/app/pages/auth/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexN6pT4Un8hYMeta?.name,
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-about___ru",
    path: "about",
    component: () => import("/app/pages/index/about.vue").then(m => m.default || m)
  },
  {
    name: blogfzfR60KsaDMeta?.name,
    path: "blog",
    component: () => import("/app/pages/index/blog.vue").then(m => m.default || m),
    children: [
  {
    name: "index-blog-slug___ru",
    path: ":slug()",
    component: () => import("/app/pages/index/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index-blog___ru",
    path: "",
    component: () => import("/app/pages/index/blog/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index-catalog___ru",
    path: "catalog",
    component: () => import("/app/pages/index/catalog.vue").then(m => m.default || m)
  },
  {
    name: "index-contacts___ru",
    path: "contacts",
    component: () => import("/app/pages/index/contacts.vue").then(m => m.default || m)
  },
  {
    name: "index-delivery-and-payment___ru",
    path: "delivery-and-payment",
    component: () => import("/app/pages/index/delivery-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "index-faq___ru",
    path: "faq",
    component: () => import("/app/pages/index/faq.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "",
    component: () => import("/app/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "index-legal___ru",
    path: "legal",
    component: () => import("/app/pages/index/legal.vue").then(m => m.default || m)
  },
  {
    name: "index-order___ru",
    path: "order",
    component: () => import("/app/pages/index/order/index.vue").then(m => m.default || m)
  },
  {
    name: "index-order-successful___ru",
    path: "order/successful",
    component: () => import("/app/pages/index/order/successful.vue").then(m => m.default || m)
  },
  {
    name: "index-product-slug___ru",
    path: "product/:slug()",
    component: () => import("/app/pages/index/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index-profile___ru",
    path: "profile",
    meta: profileZxBGWFYWGgMeta || {},
    component: () => import("/app/pages/index/profile.vue").then(m => m.default || m)
  },
  {
    name: "index-public-offer___ru",
    path: "public-offer",
    component: () => import("/app/pages/index/public-offer.vue").then(m => m.default || m)
  },
  {
    name: "index-social-responsibility___ru",
    path: "social-responsibility",
    component: () => import("/app/pages/index/social-responsibility.vue").then(m => m.default || m)
  },
  {
    name: "index-sustainable___ru",
    path: "sustainable",
    component: () => import("/app/pages/index/sustainable.vue").then(m => m.default || m)
  },
  {
    name: "index-vacancies___ru",
    path: "vacancies",
    component: () => import("/app/pages/index/vacancies.vue").then(m => m.default || m)
  }
]
  }
]