<script lang="ts" setup>
const i18n = useNuxtApp().$i18n;
const { locale, messages } = useI18n();

i18n.setLocaleMessage(locale.value, messages.value[locale.value]);
</script>

<template>
  <slot />
</template>

<i18n src="~/locales/global.yaml" />
<i18n src="~/locales/store.yaml" />
