import * as Sentry from '@sentry/vue';

import type { FetchError } from '~/api/utils/types';

function getFetchErrorMessage<T extends FetchError>(
  error: T,
): string | undefined;

function getFetchErrorMessage<T extends FetchError, M extends string>(
  error: T,
  message: M,
): string;

function getFetchErrorMessage<T extends FetchError, M extends string>(
  error: T,
  message?: M,
) {
  return (
    (process.env.NODE_ENV !== 'production'
      ? error.body?.data?.message
      : error.body?.message) || message
  );
}

export { getFetchErrorMessage };

export const logError = (error: unknown, message: string) => {
  const e =
    error instanceof Error
      ? (() => {
          error.message = `${message}: ${error.message}`;
          return error;
        })()
      : new Error(message, { cause: error });

  fetch('/api/client/error-report', {
    method: 'POST',
    body: `${e.name}: ${e.message}\n${e.stack}`,
  });

  return Sentry.captureException(e);
};
