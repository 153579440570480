export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('autosize', {
    mounted: (el: HTMLTextAreaElement) => {
      const minHeight = parseFloat(
        getComputedStyle(el).getPropertyValue('min-height'),
      );

      const observe = (
        element: HTMLTextAreaElement,
        event: string,
        handler: () => void,
      ) => {
        element.addEventListener(event, handler, false);
      };

      const resize = () => {
        el.style.height = 'auto';
        const border = Number.parseInt(getComputedStyle(el).borderTopWidth) * 2;
        el.style.setProperty(
          'height',
          (el.scrollHeight < minHeight ? minHeight : el.scrollHeight) +
            border +
            'px',
          'important',
        );
      };

      const delayedResize = () => {
        window.setTimeout(resize, 0);
      };

      const respondToVisibility = (
        element: HTMLTextAreaElement,
        callback: () => void,
      ) => {
        const intersectionObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.intersectionRatio > 0) callback();
            });
          },
          { root: document.documentElement },
        );

        intersectionObserver.observe(element);
      };

      respondToVisibility(el, resize);
      observe(el, 'change', resize);
      observe(el, 'cut', delayedResize);
      observe(el, 'paste', delayedResize);
      observe(el, 'drop', delayedResize);
      observe(el, 'input', resize);

      resize();
    },
  });
});
