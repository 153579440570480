import type { NuxtApp } from '#app/nuxt';
import request from '~/api/utils';

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      request: request.init({
        app: nuxtApp as NuxtApp,
        logout: () => useUserStore().logout(),
      }),
    },
  };
});
