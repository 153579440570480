import type { InferType } from 'yup';
import { array, boolean, date, number, object, string } from 'yup';

import request from '~/api/utils';
import { HTTPMethod } from '~/api/utils/types';

export enum OrderStatus {
  success = 'Выполнен',
  pending = 'В обработке',
}

export const addressSchema = object()
  .shape({
    city: string().required(),
    comment: string().defined().nullable(),
    entrance: string().defined().nullable(),
    flat: string().defined().nullable(),
    floor: string().defined().nullable(),
    house: string().required(),
    id: number().required(),
    intercom: string().defined().nullable(),
    street: string().required(),
    zip: string().defined().nullable(),
  })
  .required();

export const userSchema = object()
  .shape({
    id: number().nullable(),
    name: string().defined().nullable(),
    lastName: string().defined().nullable(),
    phone: string().defined(),
    email: string().defined().nullable(),
    birthDate: date().nullable(),
    age: number().nullable(),
    isPhoneConfirmed: boolean().required(),
  })
  .required();

const historyProductType = object()
  .shape({
    id: number().required(),
    oldPrice: number().required().nullable(),
    price: number().required(),
    productId: number().required(),
    skuId: number().required(),
    quantity: number().required(),
    isAvailable: boolean().required(),
    skuName: string().required(),
    skuCode: string().nullable(),
  })
  .required();

const orderHistoryItemSchema = object()
  .shape({
    id: number().required(),
    price: number().required(),
    dateCreate: string().required(),
    isCancelable: boolean().required(),
    status: string<OrderStatus>().required(),
    isPaid: boolean().required(),
    paymentType: string().required(),
    basket: object()
      .shape({ items: array().ensure().of(historyProductType).required() })
      .required(),
  })
  .required();

const userResponseSchema = object().shape({
  user: userSchema,
  orders: array().ensure().of(orderHistoryItemSchema).required(),
  addresses: array().ensure().of(addressSchema).required(),
  productOrderedIds: array().ensure().of(number().required()).required(),
  subscriptions: array()
    .ensure()
    .of(
      object()
        .shape({
          active: boolean().required(),
          code: string().required(),
          name: string().required(),
        })
        .required(),
    )
    .required(),
});

const updateUserBodySchemas = object().shape({
  name: string().required(),
  lastName: string().required(),
  phone: string().optional(),
  email: string().email().required(),
  password: string().optional().min(8),
});

const checkPhoneCodeBodySchema = object().shape({ code: string().required() });

const checkPhoneCodeResponseSchema = object()
  .shape({ codeValid: boolean().required() })
  .required();

export const houseAddressSchema = object()
  .shape({
    street: string().required(),
    house: string().required(),
  })
  .required();

export const createAddressBodySchema = houseAddressSchema.shape({
  locationCode: string().required(),
  flat: string().required(),
  zip: string().defined(),
  intercom: string().defined(),
  floor: string().defined(),
  entrance: string().defined(),
  comment: string().defined(),
});

const createAddressResponseSchema = array()
  .ensure()
  .of(addressSchema)
  .required();

const addressParamsSchema = object()
  .shape({ id: number().positive().required() })
  .required();

const updateSubscriptionsBodySchema = object()
  .shape({ subscriptions: array().of(string().required()).required() })
  .required();

const cancelOrderBodySchema = object()
  .shape({
    orderId: number().required(),
  })
  .required();

export type UserType = InferType<typeof userResponseSchema>['user'];

export type ProfileDataType = Omit<
  InferType<typeof userResponseSchema>,
  'user'
>;

export type UserAddressType = InferType<typeof addressSchema>;

export type HistoryProductType = InferType<typeof historyProductType>;

export type OrderHistoryItemType = InferType<typeof orderHistoryItemSchema>;

const UserAPI = {
  details: request.prepare({
    url: 'profile',
    schemas: { response: userResponseSchema },
    options: { methodKey: 'user:details' },
  }),
  update: request.prepare({
    url: 'profile',
    method: HTTPMethod.post,
    schemas: { response: userResponseSchema, body: updateUserBodySchemas },
  }),
  confirmPhone: request.prepare({
    url: 'code/send',
  }),
  checkPhoneCode: request.prepare({
    url: 'code/confirm',
    method: HTTPMethod.post,
    schemas: {
      response: checkPhoneCodeResponseSchema,
      body: checkPhoneCodeBodySchema,
    },
  }),
  createAddress: request.prepare({
    url: 'address',
    method: HTTPMethod.post,
    schemas: {
      body: createAddressBodySchema,
      response: createAddressResponseSchema,
    },
  }),
  updateAddress: request.prepare({
    url: ({ id }) => `address/${id}`,
    method: HTTPMethod.post,
    schemas: {
      body: createAddressBodySchema,
      params: addressParamsSchema,
      response: createAddressResponseSchema,
    },
  }),
  deleteAddress: request.prepare({
    url: ({ id }) => `address/${id}`,
    method: HTTPMethod.delete,
    schemas: {
      response: createAddressResponseSchema,
      params: addressParamsSchema,
    },
  }),
  updateSubscriptions: request.prepare({
    url: 'subscriptions',
    method: HTTPMethod.post,
    schemas: { body: updateSubscriptionsBodySchema },
  }),
  cancelOrder: request.prepare({
    url: 'orders/cancel',
    method: HTTPMethod.put,
    schemas: { body: cancelOrderBodySchema, response: userResponseSchema },
  }),
};

export default UserAPI;
