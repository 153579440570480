import type { Ref } from 'vue';

export default <T>(
  data: Ref<T>,
  storageKey: string,
  storageVersion: number,
) => {
  const userStore = useUserStore();

  const initialized = ref(false);

  const init = () => {
    if (
      !userStore.authorized &&
      typeof localStorage !== 'undefined' &&
      localStorage[storageKey]
    ) {
      const cachedData = JSON.parse(localStorage[storageKey]);
      if (cachedData.version === storageVersion) data.value = cachedData.items;
    }

    nextTick(() => {
      initialized.value = true;
    });
  };

  const reset = () => localStorage.removeItem(storageKey);

  watch(
    data,
    () => {
      if (
        typeof localStorage !== 'undefined' &&
        initialized.value &&
        !userStore.authorized
      ) {
        localStorage[storageKey] = JSON.stringify({
          version: storageVersion,
          items: data.value,
        });
      }
    },
    { deep: true },
  );

  return { init, reset, initialized };
};
