const TOKEN_KEY = 'authorization_token';

export const useTokenStore = defineStore('token', () => {
  const value = useCookie(TOKEN_KEY, {
    path: '/',
    maxAge: 365 * 24 * 60 * 60,
  });

  return { value };
});
